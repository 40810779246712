export const DOCUMENTS_MAX_FILE_LEN = 80
export const DOCUMENTS_MAX_FILE_LEN_ERROR =
  'Filename too long. Maximum of 80 characters.'
export const DOCUMENTS_FILE_REGEX = /^[\w\-_()!. ]+\.\w+$/
export const DOCUMENTS_FILE_REGEX_WO_EXT = /^[\w\-_()!. ]+$/
export const DOCUMENTS_FILE_REGEX_ERROR =
  'Invalid filename. Only alphanumeric characters and symbols ()!.-_ are allowed.'
export const DOCUMENTS_TYPE_ERROR = 'File extension not supported.'
export const DOCUMENTS_MAX_FILE_SIZE = 1073741824 // in bytes, 1 GB
export const ASYNC_DOCUMENT_SCAN_THRESHOLD_SIZE = 104857600 // in bytes, 100MB
export const DOCUMENTS_S3_DELETION_AFTER_SOFT_DELETION_DAYS = 30

export const SUPPORTED_FILE_EXTENSIONS = [
  'asc',
  'avi',
  'bmp',
  'cer',
  'csv',
  'dcm',
  'doc',
  'docx',
  'dot',
  'dxf',
  'ent',
  'gif',
  'heif',
  'hevc',
  'htm',
  'html',
  'jpeg',
  'jpg',
  'log',
  'mov',
  'mpeg',
  'mpg',
  'mpp',
  'msg',
  'mso',
  'odb',
  'odf',
  'odg',
  'odp',
  'ods',
  'odt',
  'p7m',
  'gno',
  'p7s',
  'pcx',
  'pdf',
  'png',
  'pot',
  'pps',
  'ppsx',
  'ppt',
  'pptx',
  'psd',
  'pub',
  'rtf',
  'svg',
  'sxc',
  'sxd',
  'sxi',
  'sxw',
  'tar',
  'tif',
  'tiff',
  'txt',
  'vcf',
  'vsd',
  'wav',
  'wmv',
  'xls',
  'xlsx',
  'xml',
  'zip',
] as const

export type SupportedFileExtensionType =
  typeof SUPPORTED_FILE_EXTENSIONS[number]
